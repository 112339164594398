import { useState, useEffect, useRef, useCallback } from 'react';
import AudioRecorder from 'audio-recorder-polyfill';

import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import { useStopwatch2 } from './useStopwatch';

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
// @ts-ignore
window.MediaRecorder = AudioRecorder;

export enum recordingStatusEnum {
  EMPTY,
  RECORDING,
  PAUSED,
  FINISHED
}

type tUseAudioRecorderReturnType = [
  recordingStatusEnum,
  number | undefined,
  Blob | null,
  {
    startRecording: () => void;
    pauseRecording: () => void;
    finishRecording: () => void;
    resetRecording: () => void;
    getVolume: () => number;
  }
];

type tUseAudioRecorderArgsType = {
  onRecordChange?: (audioUrl: string | null, blob?: Blob | null) => void;
  onError?: (errorMessage: string) => void;
};

export function useAudioRecorder({
  onRecordChange,
  onError
}: tUseAudioRecorderArgsType): tUseAudioRecorderReturnType {
  const [status, setStatus] = useState(recordingStatusEnum.EMPTY);
  const statusRef = useRef<recordingStatusEnum | null>(recordingStatusEnum.EMPTY);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const getVolumeRef = useRef<ReturnType<typeof volumeMeter>>(() => 0);
  const [stopwatch, seconds] = useStopwatch2();
  const audioBlob = useRef<Blob | null>(null);
  const chunks = useRef<Blob[]>([]);

  const handleDataAvailable = useCallback((_e: BlobEvent) => {
    chunks.current.push(_e.data);
  }, []);

  const handleRecorderStop = useCallback(
    (_e: any) => {
      audioBlob.current = new Blob(chunks.current);
      chunks.current = [];
      const url = URL.createObjectURL(audioBlob.current);
      onRecordChange?.(url, audioBlob.current);
      _setStatus(recordingStatusEnum.FINISHED);
      stopwatch.reset();
    },
    [onRecordChange, stopwatch]
  );

  const handleRecordError = useCallback(
    (_e: Event) => {
      onError?.((_e as any).error.name);
    },
    [onError]
  );

  const initRecorder = useCallback(async () => {
    let mRec;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      getVolumeRef.current = volumeMeter(stream);
      mRec = new MediaRecorder(stream);

      mRec.addEventListener('dataavailable', handleDataAvailable);
      mRec.addEventListener('stop', handleRecorderStop);
      mRec.addEventListener('error', handleRecordError);
    } catch (err) {
      if (typeof err === 'string') {
        onError?.(err as string);
      } else {
        onError?.((err as Error).toString());
      }
    }
    stopwatch.reset();
    return mRec || null;
  }, [handleDataAvailable, handleRecordError, handleRecorderStop, onError, stopwatch]);

  function _setStatus(status: recordingStatusEnum) {
    setStatus(status);
    statusRef.current = status;
  }
  function _start(_recorder: MediaRecorder): void {
    switch (_recorder.state) {
      case 'recording':
        break;
      case 'paused':
        _recorder.resume();
        break;
      case 'inactive':
        chunks.current = [];
        _recorder.start();
        break;
      default:
        break;
    }
  }
  const _cleanRecorderListeners = useCallback(
    (recorder: MediaRecorder) => {
      recorder.removeEventListener('dataavailable', handleDataAvailable);
      recorder.removeEventListener('stop', handleRecorderStop);
    },
    [handleDataAvailable, handleRecorderStop]
  );

  // API methods START
  const startRecording = useCallback(async () => {
    let rec = recorder;
    if (!rec) {
      rec = await initRecorder();
      setRecorder(rec);
    }
    if (rec) {
      _start(rec);
      _setStatus(recordingStatusEnum.RECORDING);
      stopwatch.start();
    }
  }, [initRecorder, recorder, stopwatch]);

  const pauseRecording = useCallback(() => {
    _setStatus(recordingStatusEnum.PAUSED);
    if (recorder) {
      recorder.pause();
      stopwatch.pause();
    }
  }, [recorder, stopwatch]);

  const finishRecording = useCallback(() => {
    stopwatch.pause();
    _setStatus(recordingStatusEnum.FINISHED);
    if (recorder) {
      recorder.stop();
      recorder.stream.getTracks().forEach((track) => track.stop());
      setRecorder(null);
    }
  }, [recorder, stopwatch]);

  const resetRecording = useCallback(() => {
    stopwatch.pause();
    stopwatch.reset();
    _setStatus(recordingStatusEnum.EMPTY);
    if (recorder) {
      recorder.stop();
      recorder.stream.getTracks().forEach((track) => track.stop());
      _cleanRecorderListeners(recorder);
      setRecorder(null);
    }
  }, [_cleanRecorderListeners, recorder, stopwatch]);

  // API methods END

  useEffect(() => {
    function _cleanRecorder(recorder: MediaRecorder) {
      if (recorder) {
        recorder.stop();
        recorder.stream.getTracks().forEach((track) => track.stop());
        _cleanRecorderListeners(recorder);
      }
    }

    return () => {
      if (recorder && recorder.state !== 'inactive') {
        _cleanRecorder(recorder);
      }
    };
  }, [recorder, _cleanRecorderListeners]);

  return [
    status,
    seconds,
    audioBlob.current,
    {
      startRecording,
      pauseRecording,
      finishRecording,
      resetRecording,
      getVolume: getVolumeRef.current
    }
  ];
}

function getMaxVolume(analyser: AnalyserNode, fftBins: Uint8Array) {
  let maxVolume = 0;
  analyser.getByteFrequencyData(fftBins);
  for (let i = 0, ii = fftBins.length; i < ii; i += 1) {
    const currentSound = fftBins[i] / 2;
    if (currentSound > maxVolume) {
      maxVolume = currentSound;
    }
  }

  return maxVolume;
}

function volumeMeter(stream: MediaStream): () => number {
  const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

  const audioContext = new AudioContext();
  const audioSourceNode = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  audioSourceNode.connect(analyser);
  analyser.fftSize = 32;
  const fftBins = new Uint8Array(analyser.frequencyBinCount);
  return () => getMaxVolume(analyser, fftBins);
}
