import { IChatMessage } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import format from 'date-fns/format';
import { FC, useCallback } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import AudioMessage from '../AudioMessage';
import EditButton from './EditButton';

interface IOutgoingAudioMessage {
  message: IChatMessage;
  showEdit: boolean;
  onEdit: (message: IChatMessage) => void;
  collapseWithNext: boolean;
  className?: string;
}

const OutgoingAudioMessage: FC<IOutgoingAudioMessage> = ({
  message,
  showEdit,
  onEdit,
  collapseWithNext,
  className
}) => {
  const handleOnEdit = useCallback(() => {
    onEdit(message);
  }, [onEdit, message]);

  if (message.media?.type !== 'audio') {
    return null;
  }

  return (
    <SWrapper>
      <SMessage className={cn(className, { collapseWithNext })}>
        {showEdit ? <EditButton onClick={handleOnEdit} /> : null}

        <SAudioMessage audioURL={message.media.url} id="replier_audio_message" />
      </SMessage>

      {message.createdAt ? (
        <SCreatedAt>{format(new Date(message.createdAt), 'HH:mm, dd MMM')}</SCreatedAt>
      ) : null}
    </SWrapper>
  );
};

export default OutgoingAudioMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-block-end: 20px;
`;

const SMessage = styled.div`
  background: var(--assignments-message-out-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  width: 100%;
  max-width: 90%;
  align-self: flex-end;
  margin-block-end: 4px;

  &.collapseWithNext {
    border-bottom-right-radius: 10px;
  }

  ${customMediaQuery('tablet')} {
    max-width: 85%;
  }
`;

const SCreatedAt = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--sub-text-color);
`;

const SAudioMessage = styled(AudioMessage)`
  margin-block-end: 4px;

  & > div {
    background: transparent;
    width: 90%;
  }

  ${customMediaQuery('tablet')} {
    & > div {
      width: 85%;
    }
  }
`;
