import { Translate } from 'i18n';
import { FC, ReactNode } from 'react';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import { HeadlessModal } from 'students/views/shared/components/modal';
import styled from 'styled-components';

interface IShortEssayConfirmationModalProps {
  onSend: () => void;
  show: boolean;
  close: () => void;
  message: ReactNode;
  continueBtnText: ReactNode;
}
const ShortEssayConfirmationModal: FC<IShortEssayConfirmationModalProps> = ({
  close,
  onSend,
  show,
  message,
  continueBtnText
}) => {
  const handleClose = () => {
    close();
  };
  const handleSend = () => {
    onSend();
  };

  return (
    <HeadlessModal
      modalKey="essay-confirmation-modal"
      show={show}
      onHide={handleClose}
      backdropClassName="ln-backdrop-blur-sm ln-bg-black/50"
      containerClassName="ln-justify-center ln-items-center"
      dialogClassName="ln-max-w-96 ln-bg-white ln-rounded-2xl ln-px-6 ln-py-8"
    >
      <div className="ln-flex ln-flex-col ln-items-center">
        <h1 className="ln-text-2xl ln-font-bold ln-mb-2">
          <Translate str="frontend.lesson_task.tasks.check.assignment.too_short_answer" />
        </h1>

        <p className="ln-text-lg ln-text-grey-sub-text ln-text-center ln-mb-6">
          {message}
        </p>

        <SLinkButton onClick={handleClose}>{continueBtnText}</SLinkButton>

        <SLinkButton className="secondary" onClick={handleSend}>
          <Translate str="frontend.lesson_task.tasks.check.assignment.send_anyway" />
        </SLinkButton>
      </div>
    </HeadlessModal>
  );
};

export default ShortEssayConfirmationModal;

const SLinkButton = styled(ButtonGeneral)`
  min-height: 3.5rem;
  background: var(--color-blue);
  border-radius: 10px;
  color: var(--color-white);
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-family: var(--lingu-heading-font-family);
  font-weight: 600;
  min-width: 20rem;
  text-align: center;
  margin-block-end: 0.875rem;

  &.secondary {
    background: rgba(45, 45, 58, 0.15);
    color: var(--color-black);
  }

  &:last-child {
    margin-block-end: 0;
  }

  ${styleInnerButton()} {
    padding: 1rem;
  }

  &:not(.secondary):hover {
    color: var(--color-white);
    text-decoration: none;
  }
`;
