import { DocumentApiTypes } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import ReactionButton from './ReactionButton';

import reaction1Icon from '../../../../../assets/reactions/reaction_1.png';
import reaction2Icon from '../../../../../assets/reactions/reaction_2.png';
import reaction3Icon from '../../../../../assets/reactions/reaction_3.png';
import reaction4Icon from '../../../../../assets/reactions/reaction_4.png';
import reaction5Icon from '../../../../../assets/reactions/reaction_5.png';

const REACTION_ICONS = [
  reaction1Icon,
  reaction2Icon,
  reaction3Icon,
  reaction4Icon,
  reaction5Icon
];

interface IReactionBlock {
  rating?: DocumentApiTypes.tDocumentRating;
  onSendRating?: (rating: DocumentApiTypes.tDocumentRating) => void;
  isLoading: boolean;
  className?: string;
}

const ReactionBlock: FC<IReactionBlock> = ({
  rating,
  onSendRating,
  isLoading,
  className
}) => (
  <SWrapper className={cn(className)}>
    <SMessage>
      <Translate str="frontend.lesson_task.tasks.check.assignment.rate_your_overall_experience" />
    </SMessage>

    <SButtonsBlock>
      {REACTION_ICONS.map((reactionUrl: string, reactionIndex: number) => (
        <SReactionButton
          isActive={rating === reactionIndex + 1}
          isDisabled={isLoading}
          onClick={() =>
            onSendRating?.((reactionIndex + 1) as DocumentApiTypes.tDocumentRating)
          }
          ariaLabel={`Reaction ${reactionIndex + 1}`}
          iconUrl={reactionUrl}
          key={reactionIndex}
        />
      ))}
    </SButtonsBlock>
  </SWrapper>
);

export default ReactionBlock;

const SWrapper = styled.div`
  position: relative;
  padding-top: 28px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--heading-text-color);
    opacity: 0.1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
`;

const SMessage = styled.p`
  margin: 0 0 12px;
  color: var(--heading-text-color);
  opacity: 0.5;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const SButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SReactionButton = styled(ReactionButton)`
  margin-inline-end: 8px;

  &:last-child {
    margin-inline-end: 0;
  }
`;
