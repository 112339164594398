import React from 'react';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import styled from 'styled-components';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import EditIcon from '../../../../../assets/edit_icon.svg';

type tProps = {
  onClick?: () => void;
};
const EditButton: React.FC<tProps> = ({ onClick }) => {
  return (
    <SEditButton onClick={onClick} title="edit">
      <UrlIcon
        url={EditIcon}
        height="22px"
        width="22px"
        color="var(--heading-text-color)"
      />
    </SEditButton>
  );
};

export default EditButton;

const SEditButton = styled(ButtonGeneral)`
  border: none;
  border-radius: 8px;
  padding: 5px;
  background-color: var(--assignments-message-in-background);
  box-shadow: var(--assignments-card-shadow);
  transition: background-color 0.3s;
  position: absolute;
  top: 4px;
  right: 4px;

  &:hover {
    background-color: var(--assignments-message-in-background-hover);
  }
`;
