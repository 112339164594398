import { tClientLicence, tClientLicences } from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { LicenseSelector } from '../../licenses';

type tAssignmentLicenseSelector = {
  licensesSubmitFor: tClientLicences;
  currentLicense: tClientLicence;
  changeCurrentLicense: (license: NonNullable<tClientLicence>) => Promise<any>;
};

const AssignmentLicenseSelector = ({
  licensesSubmitFor,
  currentLicense,
  changeCurrentLicense
}: tAssignmentLicenseSelector): JSX.Element | null =>
  currentLicense ? (
    <SLicenseSelectionBlock>
      <SLicenseSelectorText />

      <SLicenseSelector
        licences={licensesSubmitFor}
        currentLicence={currentLicense}
        onChange={changeCurrentLicense}
      />
    </SLicenseSelectionBlock>
  ) : null;

export default AssignmentLicenseSelector;

const SLicenseSelectionBlock = styled.div`
  background: #00000040;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-block-end: 38px;
  padding: 12px 8px 8px;
  width: 100%;

  ${customMediaQuery('tablet')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-block-end: 28px;
    padding: 12px;
    border-radius: 20px;
  }
`;

const SLicenseSelector = styled(LicenseSelector)`
  margin-block-start: 8px;

  ${customMediaQuery('tablet')} {
    margin-block-start: 0;
    margin-inline-start: 16px;
    width: 295px;
  }
`;

const SLicenseSelectorText = styled(Translate).attrs({
  str: 'frontend.licences.send_assignment_to',
  params: { defaultValue: 'Send this assignment to:' }
})`
  color: white;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 22px;
  display: inline-block;
  margin-inline-start: 8px;
`;
