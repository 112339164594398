import { tClientLicence } from '@adeptlms/lingu-students-react-shared';
import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import { Translate } from 'i18n';
import dropdownIcon from 'students/views/shared/assets/icons/dropdown_icon.svg';
import pickedBlue from 'students/views/shared/assets/icons/picked-blue.svg';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import LicenseAvatar from '../../licenses/LicenseAvatar';

type tAssignmentAILicenseSelector = {
  licensesSubmitFor?: Array<{
    avgResponseTime: number | null;
    id: number;
    imageURL: string | null;
    name: string;
    type: string;
  }>;
  currentLicense: tClientLicence;
  changeCurrentLicense: (license: number) => Promise<any>;
};

const AssignmentAILicenseSelector = ({
  licensesSubmitFor,
  currentLicense,
  changeCurrentLicense
}: tAssignmentAILicenseSelector): JSX.Element | null => {
  if (!currentLicense) {
    return null;
  }

  const currentReviewer = licensesSubmitFor?.find(
    (licence) => licence.id === currentLicense.id
  );

  if (!currentReviewer || !licensesSubmitFor) {
    return null;
  }

  if (licensesSubmitFor.length < 3) {
    return (
      <SLicenseSelectionBlock>
        <SLicenseSelectorText />

        <Row>
          {licensesSubmitFor.map((licence) => (
            <SLicenceLabel
              key={licence.id}
              className={cn(licence.id === currentLicense.id ? 'selected' : '')}
              onClick={() => changeCurrentLicense(licence.id)}
            >
              <div className="ln-flex-shrink-0">
                <LicenseAvatar src={licence.imageURL} />
              </div>

              <SLicenceTextColumn>
                <SLicenceName>
                  {' '}
                  {licence.type === 'ai_bot' ? licence.name : 'Teacher review'}
                </SLicenceName>

                <SLicenceStatus>
                  {licence.type === 'ai_bot' ? (
                    <span>AI will review and provide feedback instantly.</span>
                  ) : (
                    <span>
                      Get a review from {licence.name}, usually within{' '}
                      {licence.avgResponseTime || ''} days.
                    </span>
                  )}
                </SLicenceStatus>
              </SLicenceTextColumn>
            </SLicenceLabel>
          ))}
        </Row>
      </SLicenseSelectionBlock>
    );
  }

  return (
    <SLicenseSelectionBlock>
      <SLicenseSelectorText />

      <Listbox value={currentReviewer} onChange={({ id }) => changeCurrentLicense(id)}>
        <SSelectorWrapper>
          <SListboxButton>
            <SLicenceLabel style={{ width: '100%', background: 'transparent' }}>
              <div className="ln-flex-shrink-0">
                <LicenseAvatar src={currentReviewer.imageURL} />
              </div>

              <SLicenceTextColumn>
                <SLicenceName>
                  {currentReviewer.type === 'ai_bot'
                    ? currentReviewer.name
                    : 'Teacher review'}
                </SLicenceName>

                <SLicenceStatus>
                  {currentReviewer.type === 'ai_bot' ? (
                    <span>AI will review and provide feedback instantly.</span>
                  ) : (
                    <span>
                      Get a review from {currentReviewer.name}, usually within{' '}
                      {currentReviewer.avgResponseTime || ''} days.
                    </span>
                  )}
                </SLicenceStatus>
              </SLicenceTextColumn>
            </SLicenceLabel>

            <SDropdownIconWrapper>
              <UrlIcon url={dropdownIcon} height="18px" width="18px" />
            </SDropdownIconWrapper>
          </SListboxButton>

          <SListboxOptions>
            {licensesSubmitFor.map((licence) => {
              return (
                <Listbox.Option
                  key={licence.id}
                  value={licence}
                  className={({ active, selected }) =>
                    cn('licence_selector-option', {
                      selected,
                      active
                    })
                  }
                >
                  <SLicenceLabel
                    style={{ width: '100%', background: 'transparent' }}
                    onClick={() => changeCurrentLicense(licence.id)}
                  >
                    <div className="ln-flex-shrink-0">
                      <LicenseAvatar src={licence.imageURL} />
                    </div>

                    <SLicenceTextColumn>
                      <SLicenceName>
                        {licence.type === 'ai_bot' ? licence.name : 'Teacher review'}
                      </SLicenceName>

                      <SLicenceStatus>
                        {licence.type === 'ai_bot' ? (
                          <span>AI will review and provide feedback instantly.</span>
                        ) : (
                          <span>
                            Get a review from {licence.name}, usually within{' '}
                            {licence.avgResponseTime || ''} days.
                          </span>
                        )}
                      </SLicenceStatus>
                    </SLicenceTextColumn>
                  </SLicenceLabel>

                  {licence.id === currentLicense?.id ? (
                    <SSelectedIconWrapper>
                      <UrlIcon url={pickedBlue} height="24px" width="24px" />
                    </SSelectedIconWrapper>
                  ) : null}
                </Listbox.Option>
              );
            })}
          </SListboxOptions>
        </SSelectorWrapper>
      </Listbox>
    </SLicenseSelectionBlock>
  );
};

export default AssignmentAILicenseSelector;

const SLicenseSelectionBlock = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  margin-block-end: 38px;
  padding: 20px;
  width: 100%;

  ${customMediaQuery('tablet')} {
    margin-block-end: 28px;
  }
`;

const SLicenseSelectorText = styled(Translate).attrs({
  str: 'frontend.licences.who_should_review_your_work',
  params: { defaultValue: 'Who should review your work?' }
})`
  color: white;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 22px;
  display: inline-block;
  margin-block-end: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${customMediaQuery('tablet')} {
    flex-direction: row;
  }
`;

const SLicenceLabel = styled.div`
  background: rgba(2, 11, 42, 0.8);
  padding: 8px;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  display: flex;

  &.selected {
    background: rgba(2, 11, 42, 0.5);
    border: 3px solid var(--color-blue);
  }

  ${customMediaQuery('tablet')} {
    padding: 12px;
    width: 50%;
  }
`;

const SLicenceTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  min-width: 0;
  padding-inline-start: 8px;
`;

const SLicenceName = styled.span`
  color: var(--color-white);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0 0 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

const SLicenceStatus = styled.span`
  color: var(--color-white);
  opacity: 0.8;
  font-size: 0.75rem;
  line-height: 0.875rem;

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;

const SSelectorWrapper = styled.div`
  position: relative;
  z-index: 10;

  & .license_selector-name {
    color: white;
  }
`;

const SListboxButton = styled(Listbox.Button)`
  width: 100%;
  padding: 0;
  background: rgba(2, 11, 42, 0.8);
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${customMediaQuery('tablet')} {
    border-radius: 20px;
  }
`;

const SListboxOptions = styled(Listbox.Options)`
  background: #232748;
  position: absolute;
  overflow: auto;
  max-height: 400px;
  border-radius: 16px;
  padding: 12px;
  width: 100%;

  & .licence_selector-option {
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 1px 0;
    cursor: pointer;
    padding: 0 8px;
  }

  & .licence_selector-option.selected,
  & .licence_selector-option.selected.active,
  & .licence_selector-option.active {
    background: #ffffff10;
  }
`;

const SSelectedIconWrapper = styled.div`
  padding-inline-start: 6px;
`;

const SDropdownIconWrapper = styled.div`
  padding: 6px;
  background: #ffffff10;
  border-radius: 8px;
  margin-inline-start: 8px;
  margin-inline-end: 16px;
`;
