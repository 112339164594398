import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import { customMediaQuery, SPrimaryButton } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IUpdateResponseBlock {
  onUpdate: () => void;
  className?: string;
}

const UpdateResponseBlock: FC<IUpdateResponseBlock> = ({ onUpdate, className }) => (
  <SUpdateResponseBlock className={cn(className)}>
    <SUpdateResponseMessage>
      <Translate str="frontend.member.assignments.teacher_asked_to_update_assignment" />
    </SUpdateResponseMessage>

    <SUpdateResponseButton onClick={onUpdate}>
      <Translate str="frontend.member.assignments.update_my_response" />
    </SUpdateResponseButton>
  </SUpdateResponseBlock>
);

export default UpdateResponseBlock;

const SUpdateResponseBlock = styled.div`
  background: var(--assignments-feedback-message-background);
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-block-start: 16px;

  ${customMediaQuery('tablet')} {
    flex-direction: row;
    padding: 16px;
    margin-block-start: 24px;
  }
`;

const SUpdateResponseMessage = styled.h3`
  margin: 0 0 12px;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--heading-text-color);
  transition: background-color 0.3s;
  text-align: center;

  ${customMediaQuery('tablet')} {
    margin: 0 16px 0 0;
    text-align: start;
  }
`;

const SUpdateResponseButton = styled(SPrimaryButton)`
  box-shadow: 0 0 10px rgba(11, 63, 79, 0.26);
  min-height: 3.125rem;
  font-size: 1rem;
  width: 100%;
  margin: 0;

  ${customMediaQuery('tablet')} {
    width: 212px;
  }
`;
