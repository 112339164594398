import { Translate } from 'i18n';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import GetFullAccessMessageWithButton from '../../licenses/GetFullAccessMessageWithButton';

const AssignmentNoLicenseToAccept = (): JSX.Element => (
  <SNoLicenseToAcceptBlock>
    <GetFullAccessMessageWithButton>
      <SNoLicenseToAcceptTextBlock>
        <p>
          <SBoldTranslate
            str="frontend.licences.need_licenses_to_send_assignment_message_1"
            params={{ defaultValue: 'You need a license to send assignments' }}
          />
        </p>
        <p>
          <Translate
            str="frontend.licences.need_licenses_to_send_assignment_message_2"
            params={{
              defaultValue: 'To answer this task, you need to get full access or skip it'
            }}
          />
        </p>
      </SNoLicenseToAcceptTextBlock>
    </GetFullAccessMessageWithButton>
  </SNoLicenseToAcceptBlock>
);

export default AssignmentNoLicenseToAccept;

const SBoldTranslate = styled(Translate)`
  font-weight: 700;
  padding-bottom: 4px;
  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
  }
`;

const SNoLicenseToAcceptBlock = styled.div`
  margin-block-end: 38px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 28px;
  }
`;

const SNoLicenseToAcceptTextBlock = styled.div`
  color: white;

  & p {
    margin: 0;
    padding: 0;
  }
`;
