import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

interface IControlledMediumZoom {
  children: ({
    isZoomed,
    openZoomedImage
  }: {
    isZoomed: boolean;
    openZoomedImage: () => void;
  }) => ReactNode;
}

const ControlledMediumZoom: FC<IControlledMediumZoom> = ({ children }) => {
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const openZoomedImage = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom: boolean) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      wrapStyle={{ width: '100%' }}
      overlayBgColorEnd="rgba(45, 45, 58, 0.9)"
    >
      {children({ isZoomed, openZoomedImage })}
    </ControlledZoom>
  );
};

export default ControlledMediumZoom;
