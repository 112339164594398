import { tUseAssignmentReturnValue } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { Translate } from 'i18n';
import { memo } from 'react';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import AudioIcon from '../assets/audio_icon.svg';
import EssayIcon from '../assets/essay_icon.svg';
import FileIcon from '../assets/file_icon.svg';

interface ITag {
  type: tUseAssignmentReturnValue['inputType'];
  className?: string;
}

const AssignmentTag = ({ type, className }: ITag): JSX.Element => {
  let text = 'frontend.lesson_task.tasks.check.assignment.unknown_task_type';
  let icon;

  switch (type) {
    case 'text':
      text = 'frontend.lesson_task.tasks.check.assignment.write_essay';
      icon = EssayIcon;

      break;

    case 'audio':
      text = 'frontend.lesson_task.tasks.check.assignment.record_voice';
      icon = AudioIcon;

      break;

    case 'file':
      text = 'frontend.lesson_task.tasks.check.assignment.upload_files';
      icon = FileIcon;

      break;

    default:
      break;
  }

  return (
    <SWrapper className={cn(className)}>
      {icon && <UrlIcon url={icon} color="#ffffff" />}

      <SText>
        <Translate str={text} />
      </SText>
    </SWrapper>
  );
};

export default memo(AssignmentTag);

const SWrapper = styled.div`
  background: var(--assignments-tag-background);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;

  & > div {
    min-height: 12px;
    min-width: 12px;
    margin-inline-end: 4px;
  }

  ${customMediaQuery('tablet')} {
    padding: 10px 12px;

    & > div {
      min-height: 16px;
      min-width: 16px;
    }
  }
`;

const SText = styled.span`
  color: var(--color-white);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
  }
`;
