import React, { FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import VideoContent from 'students/views/shared/components/MediaPlayer/VideoPlayer/VideoContent';

interface IIncomingVideoMessage {
  videoURL: string;
  className?: string;
}

const IncomingVideoMessage: FC<IIncomingVideoMessage> = ({ videoURL, className }) => (
  <SWrapper className={cn(className)}>
    <VideoContent videoURL={videoURL} />
  </SWrapper>
);

export default IncomingVideoMessage;

const SWrapper = styled.div`
  width: 100%;
`;
