import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { tGiphyImage } from '@adeptlms/lingu-students-react-shared';

import ControlledMediumZoom from '../ControlledMediumZoom';
import { useBreakPoint } from '../../hooks';
import LinkElem from '../LinkElem';

interface IProps {
  image?: string | null;
  smallImage?: string | null;
  giphyImage?: tGiphyImage;
  cover?: boolean | null;
  className?: string;
  responsiveGiphy?: boolean;
  isZoomable?: boolean;
  alt: string;
}

const ImageContent: React.FC<IProps> = ({
  image,
  smallImage,
  giphyImage,
  cover,
  className,
  responsiveGiphy = true,
  isZoomable,
  alt
}) => {
  const isMobile = useBreakPoint('md', true);

  if (image) {
    if (isZoomable) {
      return (
        <ControlledMediumZoom>
          {({ isZoomed, openZoomedImage }) => (
            <SCoveringContent
              className={cn(className, { cover })}
              onClick={openZoomedImage}
            >
              <picture>
                {!!smallImage && (
                  <source
                    media="(max-width: 400px)"
                    srcSet={isMobile && isZoomed ? image : smallImage}
                  />
                )}
                <img className="ln-min-w-full" src={image} alt={alt || ''} />
              </picture>
            </SCoveringContent>
          )}
        </ControlledMediumZoom>
      );
    }

    return (
      <SCoveringContent className={cn(className, { cover })}>
        <picture>
          {!!smallImage && <source media="(max-width: 400px)" srcSet={smallImage} />}
          <img className="ln-min-w-full" src={image} alt="" />
        </picture>
      </SCoveringContent>
    );
  }

  if (giphyImage) {
    return (
      <SGiphyBlock className={cn(className, { responsiveGiphy })}>
        <img
          className="ln-min-w-full"
          src={giphyImage.originalURL}
          alt={giphyImage.title}
        />

        <p>
          <LinkElem to={giphyImage.url} target="_blank" rel="noreferrer">
            via GIPHY
          </LinkElem>
        </p>
      </SGiphyBlock>
    );
  }

  return null;
};

export default ImageContent;

const SCoveringContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 24px 24px 0;

  &.cover {
    padding: 0;
    width: 100%;

    & img {
      object-fit: cover;
      width: 100%;
    }
  }

  picture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    object-fit: contain;
    object-position: 50% 50%;
  }

  &,
  picture,
  & img {
    max-height: 232px;
  }

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    &,
    picture,
    & img {
      max-height: 496px;
    }

    padding: 70px 70px 0;

    &.cover {
      padding: 0;
    }
  }
`;

const SGiphyBlock = styled.div`
  max-height: 232px;
  margin: 12px auto 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: calc(100% - 24px);

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    &.responsiveGiphy {
      max-height: 496px;
      margin: 70px auto 0;
      max-width: calc(100% - 140px);
    }
  }

  & img {
    object-fit: contain;
    height: 200px;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.linguBptMd}) {
      &.responsiveGiphy {
        height: 450px;
      }
    }
  }
`;
