import React, { FC } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';

interface IReactionButton {
  isActive: boolean;
  isDisabled: boolean;
  onClick: () => void;
  iconUrl: string;
  className?: string;
  ariaLabel?: string;
}

const ReactionButton: FC<IReactionButton> = ({
  isActive,
  isDisabled,
  onClick,
  iconUrl,
  ariaLabel,
  className
}) => (
  <SReactionButton
    className={cn(className, { isActive })}
    disabled={isDisabled}
    onClick={onClick}
    aria-label={ariaLabel}
  >
    <UrlIcon url={iconUrl} height="32px" width="32px" />
  </SReactionButton>
);

export default ReactionButton;

const SReactionButton = styled(ButtonGeneral)`
  background: var(--assignments-message-in-background);
  border: none;
  border-radius: 12px;
  transition: background 0.3s;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 375px) {
    padding: 12px;

    & > div {
      min-height: 28px;
      min-width: 28px;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover {
    background: var(--assignments-message-in-background-hover);
  }

  &.isActive {
    background: var(--heading-text-color);

    &:hover {
      background: var(--heading-text-color);
    }
  }
`;
