import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import {
  customMediaQuery,
  SPrimaryButton,
  SSecondaryButton
} from 'students/views/shared/styled';
import styled from 'styled-components';

interface IAcceptButtonsBlock {
  onSkip: () => void;
  onAccept?: () => void;
  className?: string;
  acceptText?: JSX.Element;
  skipText?: JSX.Element;
}

const AcceptButtonsBlock: FC<IAcceptButtonsBlock> = ({
  onSkip,
  onAccept,
  acceptText,
  skipText,
  className
}) => (
  <SStartActionsBlock className={cn(className)}>
    <SSecondaryBtn onClick={onSkip}>
      {skipText ?? <Translate str="frontend.lesson_task.tasks.check.assignment.skip" />}
    </SSecondaryBtn>

    <SPrimaryBtn onClick={onAccept} disabled={!onAccept}>
      {acceptText ?? (
        <Translate str="frontend.lesson_task.tasks.check.assignment.accept" />
      )}
    </SPrimaryBtn>
  </SStartActionsBlock>
);

export default AcceptButtonsBlock;

const SStartActionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > button {
    width: 100%;

    &:first-child {
      margin-inline-end: 14px;

      ${customMediaQuery('tablet')} {
        margin-inline-end: 20px;
      }
    }
  }
`;

const SPrimaryBtn = styled(SPrimaryButton)`
  box-shadow: 0 0 10px rgba(11, 63, 79, 0.26);
  min-height: 3.125rem;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:disabled {
    background-color: rgb(0, 165, 215) !important;
  }
`;

const SSecondaryBtn = styled(SSecondaryButton)`
  background-color: var(--assignments-skip-button-background) !important;
  color: var(--assignments-skip-button-color) !important;
  min-height: 3.125rem;
  font-size: 1rem;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: var(--heading-text-color);
  }

  &:disabled {
    background-color: rgba(45, 45, 58, 0.1) !important;
    opacity: 0.8;
  }
`;
