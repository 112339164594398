import { IChatMessage } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import format from 'date-fns/format';
import { FC, useCallback } from 'react';
import { Spinner } from 'students/views/shared/components/Spinner';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import EditButton from './EditButton';

interface IOutgoingTextMessage {
  message: IChatMessage;
  showEdit: boolean;
  onEdit: (message: IChatMessage) => void;
  collapseWithNext: boolean;
  className?: string;
}

const OutgoingTextMessage: FC<IOutgoingTextMessage> = ({
  message,
  showEdit,
  onEdit,
  collapseWithNext,
  className
}) => {
  const handleOnEdit = useCallback(() => {
    onEdit(message);
  }, [onEdit, message]);

  if (message.state === 'loading') {
    return (
      <SWrapper>
        <SSpinnerMessage className={cn(className, { collapseWithNext })}>
          <Spinner variant="light" />
        </SSpinnerMessage>
      </SWrapper>
    );
  }

  return (
    <SWrapper>
      <SMessage className={cn(className, { collapseWithNext })}>
        {showEdit ? <EditButton onClick={handleOnEdit} /> : null}

        <SMessageText>{message.text}</SMessageText>
      </SMessage>

      {message.createdAt ? (
        <SCreatedAt>{format(new Date(message.createdAt), 'HH:mm, dd MMM')}</SCreatedAt>
      ) : null}
    </SWrapper>
  );
};

export default OutgoingTextMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-block-end: 20px;
`;

const SSpinnerMessage = styled.div`
  background: var(--assignments-message-out-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  padding: 16px;
  align-self: flex-end;
`;

const SMessage = styled.div`
  background: var(--assignments-message-out-background);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  padding: 16px;
  width: 100%;
  max-width: 90%;
  align-self: flex-end;

  ${customMediaQuery('tablet')} {
    padding: 20px;
    max-width: 85%;
  }
`;

const SCreatedAt = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--sub-text-color);
`;

const SMessageText = styled.p`
  margin: 0;
  color: var(--heading-text-color);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  white-space: pre-line;
`;
