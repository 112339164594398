import { tClientLicence } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import React from 'react';
import dropdownIcon from 'students/views/shared/assets/icons/dropdown_icon.svg';
import questionIcon from 'students/views/shared/assets/icons/question_in_circle.svg';
import styled from 'styled-components';

import { useTypedDispatch } from 'students/stores/_utils';
import { modalActions } from 'students/stores/modal';
import UrlIcon from '../../components/UrlIcon';
import { customMediaQuery, SNoStyleGeneralButton } from '../../styled';
import LicenceLabel from './LicenceActionCard/Cources/LicenceLabel';

type tProps = {
  licence: NonNullable<tClientLicence>;
  className?: string;
  nameClassName?: string;
  questionButton?: boolean;
  dropdownButton?: boolean;
};
const LicenceLabelWithButtons: React.FC<tProps> = ({
  licence,
  className,
  dropdownButton,
  questionButton,
  nameClassName
}) => {
  const labelClassName = cn({ questionButton, dropdownButton });
  const dispatch = useTypedDispatch();
  return (
    <SWrapper className={cn(className)}>
      <SLicenceLabel
        license={licence}
        className={cn(labelClassName)}
        nameClassName={nameClassName}
        withTeamName
      />
      {questionButton ? (
        <SQuestionIconButton
          onClick={(e) => {
            dispatch(modalActions.open('getLicenceModal'));
            e.preventDefault();
          }}
        >
          <UrlIcon url={questionIcon} height="28px" width="28px" />
        </SQuestionIconButton>
      ) : null}
      {dropdownButton ? (
        <SDropdownIconWrapper>
          <UrlIcon url={dropdownIcon} height="18px" width="18px" />
        </SDropdownIconWrapper>
      ) : null}
    </SWrapper>
  );
};

export default LicenceLabelWithButtons;

const SWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  background: rgb(2, 11, 42, 0.8);
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${customMediaQuery('tablet')} {
    border-radius: 20px;
  }
`;

const ICON_WIDTH = 38; // 18(width) + 6(padding) * 2 + 8(margin)
const QUESTION_WIDTH = 52;

const SLicenceLabel = styled(LicenceLabel)`
  width: 100%;
  &.questionButton,
  &.dropdownButton {
    width: calc(100% - ${ICON_WIDTH}px);
  }

  &.questionButton.dropdownButton {
    width: calc(100% - ${ICON_WIDTH + QUESTION_WIDTH}px);
  }
`;

const SQuestionIconButton = styled(SNoStyleGeneralButton)`
  padding: 8px;
  border-radius: 12px;
  background: #ffffff30;
  margin-inline-start: 8px;

  &:focus,
  &:hover {
    background: #ffffff40;
  }
`;
const SDropdownIconWrapper = styled.div`
  padding: 6px;
  background: #ffffff10;
  border-radius: 8px;
  margin-inline-start: 8px;
`;
