import { Translate } from 'i18n';
import { FC } from 'react';
import linguIcon from 'students/views/shared/assets/lingu_icon.svg';
import Picture from 'students/views/shared/components/Picture';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IIncomingMessageFooter {
  authorName: string;
  authorInitials?: string | null;
  authorAvatar: string | null;
}

const IncomingMessageFooter: FC<IIncomingMessageFooter> = ({
  authorName,
  authorInitials,
  authorAvatar
}) => {
  const renderAvatar = () => {
    if (authorAvatar) {
      return (
        <SAuthorAvatar src={authorAvatar} fallbackImage={linguIcon} alt={authorName} />
      );
    }

    if (authorInitials) {
      return <SAuthorInitials>{authorInitials}</SAuthorInitials>;
    }

    return <SAuthorAvatar src={linguIcon} alt={authorName} />;
  };

  return (
    <SMessageFooter>
      <SAuthorWrapper>
        {renderAvatar()}

        <SAuthorName>{authorName}</SAuthorName>
      </SAuthorWrapper>

      <SCreatedAt>
        <Translate str="frontend.member.assignments.powered_by_open_ai" />
      </SCreatedAt>
    </SMessageFooter>
  );
};

const SMessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;

  ${customMediaQuery('tablet')} {
    width: 85%;
  }
`;

const SAuthorWrapper = styled.div`
  margin-block-start: -18px;
  margin-inline-start: -40px;
  display: flex;
  align-items: flex-end;

  ${customMediaQuery('tablet')} {
    margin-block-start: -30px;
    margin-inline-start: -56px;
  }
`;

const SAuthorAvatar = styled(Picture)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-inline-end: 6px;
  object-fit: contain;

  ${customMediaQuery('tablet')} {
    width: 48px;
    height: 48px;
    margin-inline-end: 8px;
  }
`;

const SAuthorInitials = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-inline-end: 6px;
  font-size: 0.875rem;
  line-height: 1rem;
  padding-top: 2px;
  color: var(--color-black);
  background-color: #dbe4cf;
  display: flex;
  justify-content: center;
  align-items: center;

  ${customMediaQuery('tablet')} {
    width: 48px;
    height: 48px;
    margin-inline-end: 8px;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

const SAuthorName = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--heading-text-color);
  opacity: 0.8;

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const SCreatedAt = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--sub-text-color);
`;

export default IncomingMessageFooter;
