import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, useMemo } from 'react';
import linguLogo from 'students/views/shared/assets/lingu_circle_icon.png';
import Picture from 'students/views/shared/components/Picture';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IMessage {
  name: string;
  avatar?: string | null;
  initials?: string | null;
  responseTime?: string | null;
  className?: string;
}

const DEFAULT_RESPONSE_TIME_DAYS = '1-2';

const FeedbackMessage: FC<IMessage> = ({
  name,
  avatar,
  initials,
  responseTime,
  className
}) => {
  const ownerAvatar = useMemo(() => {
    if (avatar) {
      return <SOwnerAvatar src={avatar} fallbackImage={linguLogo} alt={name} />;
    }

    if (initials) {
      return <SOwnerInitials>{initials}</SOwnerInitials>;
    }

    return <SOwnerAvatar src={linguLogo} alt={name} />;
  }, [avatar, initials, name]);

  return (
    <SMessage className={cn(className)}>
      {ownerAvatar}

      <SMessageText>
        {responseTime ? (
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.author_will_review_your_answer"
            params={{ author: name, time: responseTime }}
          />
        ) : (
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.author_will_review_your_answer_default"
            params={{ author: name, days: DEFAULT_RESPONSE_TIME_DAYS }}
          />
        )}
      </SMessageText>
    </SMessage>
  );
};

export default FeedbackMessage;

const SMessage = styled.div`
  background: var(--assignments-feedback-message-background);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;

  ${customMediaQuery('tablet')} {
    padding: 16px 20px;
  }
`;

const SOwnerAvatar = styled(Picture)`
  object-fit: contain;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-inline-end: 8px;
  border-radius: 50%;

  ${customMediaQuery('tablet')} {
    width: 56px;
    height: 56px;
    margin-inline-end: 12px;
  }
`;

const SOwnerInitials = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-inline-end: 8px;
  border-radius: 50%;
  text-transform: uppercase;
  color: var(--heading-text-color);
  // color: var(--assignments-message-out-background);
  background-color: rgb(0 165 215);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${customMediaQuery('tablet')} {
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin-inline-end: 12px;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

const SMessageText = styled.p`
  margin: 0;
  padding: 0;
  color: var(--heading-text-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
`;
