import React, { useCallback } from 'react';

import { tClientLicence, tClientLicences } from '@adeptlms/lingu-students-react-shared';
import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import { useTypedDispatch } from 'students/stores/_utils';
import { modalActions } from 'students/stores/modal';
import pickedBlue from 'students/views/shared/assets/icons/picked-blue.svg';
import styled from 'styled-components';
import UrlIcon from '../../components/UrlIcon';
import { customMediaQuery } from '../../styled';
import LicenceLabel from './LicenceActionCard/Cources/LicenceLabel';
import LicenceLabelWithButtons from './LicenceLabelWithButtons';

type tLicence = NonNullable<tClientLicence>;
type tProps = {
  className?: string;
  onChange?: (licence: tLicence) => void;
  licences: tClientLicences;
  currentLicence: tLicence;
};
const LicenceSelector: React.FC<tProps> = ({
  className,
  onChange,
  licences,
  currentLicence
}) => {
  const dispatch = useTypedDispatch();
  const showLicenceModal = useCallback(() => {
    dispatch(modalActions.open('getLicenceModal'));
  }, [dispatch]);

  const currentLicenceChangeHandler = useCallback(
    (selectedLicence: tLicence) => {
      if (selectedLicence.computed.status === 'inactive') {
        showLicenceModal();
      } else {
        onChange?.(selectedLicence);
      }
    },
    [showLicenceModal, onChange]
  );

  if (!currentLicence) return <div>...</div>;

  const currentLicenceIsInactive = currentLicence.computed.status === 'inactive';

  if (licences.length === 1 && currentLicence) {
    return (
      <LicenceLabelWithButtons
        licence={currentLicence}
        className={cn(className)}
        questionButton={false}
        dropdownButton
        nameClassName="license_selector-name"
      />
    );
  }
  return (
    <Listbox value={currentLicence} onChange={currentLicenceChangeHandler}>
      <SSelectorWrapper className={cn(className)}>
        <SListboxButton>
          <LicenceLabelWithButtons
            licence={currentLicence}
            dropdownButton={licences.length > 1}
            questionButton={currentLicenceIsInactive}
            nameClassName="license_selector-name"
          />
        </SListboxButton>

        <SListboxOptions>
          {licences.map((licence) => {
            return (
              <Listbox.Option
                key={licence.id}
                value={licence}
                className={({ active, selected }) =>
                  cn('licence_selector-option', {
                    selected,
                    active
                  })
                }
              >
                <SOptionLabel
                  key={licence.id}
                  license={licence}
                  nameClassName="license_selector-name"
                  withTeamName
                />
                {licence.id === currentLicence?.id ? (
                  <SSelectedIconWrapper>
                    <UrlIcon url={pickedBlue} height="24px" width="24px" />
                  </SSelectedIconWrapper>
                ) : null}
              </Listbox.Option>
            );
          })}
        </SListboxOptions>
      </SSelectorWrapper>
    </Listbox>
  );
};

export default LicenceSelector;

const SSelectorWrapper = styled.div`
  position: relative;
  z-index: 10;

  & .license_selector-name {
    color: white;
  }
`;
const SListboxButton = styled(Listbox.Button)`
  width: 100%;
  padding: 0;
  background: rgba(2, 11, 42, 0.8);
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${customMediaQuery('tablet')} {
    border-radius: 20px;
  }
`;

const SOptionLabel = styled(LicenceLabel)`
  width: calc(100% - 36px);
`;

const SListboxOptions = styled(Listbox.Options)`
  background: #232748;
  position: absolute;
  overflow: auto;
  max-height: 400px;
  border-radius: 16px;
  padding: 12px;
  width: 100%;

  & .licence_selector-option {
    padding: 8px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 1px 0;
    cursor: pointer;
  }

  & .licence_selector-option.selected,
  & .licence_selector-option.selected.active,
  & .licence_selector-option.active {
    background: #ffffff10;
  }
`;

const SSelectedIconWrapper = styled.div`
  padding-inline-start: 12px;
`;
