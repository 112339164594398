import { tClientLicences } from '@adeptlms/lingu-students-react-shared';
import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import StackedAvatars from '../../components/StackedAvatars';
import LicenseAvatar from './LicenseAvatar';
import { customMediaQuery } from '../../styled';

type tProps = {
  licenses: tClientLicences;
  className?: string;
};
const StackedLicenses: React.FC<tProps> = ({ licenses, className }) => {
  const items = licenses.map((license) => ({
    url: license.imageURL || null,
    id: license.id.toString(),
    alt: license.name
  }));
  return (
    <SStackedAvatars
      className={cn(className)}
      items={items}
      withNumber
      counterClassName="stacked_licences-counter"
      avatarWrapperClassName="stacked_licences-avatar_wrapper"
      render={({ alt, url }) => <LicenseAvatar src={url} alt={alt} lazy />}
    />
  );
};

export default StackedLicenses;

const SStackedAvatars = styled(StackedAvatars)`
  & .stacked_licences-avatar_wrapper {
    background-color: var(--common-licence-card-background) !important;
    border-color: var(--common-licence-card-background) !important;
    flex-shrink: 0;
  }

  & .stacked_licences-counter {
    border: 2px solid var(--common-licence-card-background);
    box-sizing: content-box;
    width: 48px;
    height: 48px;
    flex-shrink: 0;

    ${customMediaQuery('tablet')} {
      width: 52px;
      height: 52px;
    }
  }
`;
