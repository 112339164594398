import cn from 'classnames';
import { FC } from 'react';
import LinkElem from 'students/views/shared/components/LinkElem';
import styled from 'styled-components';

interface IIncomingTextMessage {
  title?: string | null;
  text?: string | null;
  link?: {
    url: string | null;
    title: string | null;
  } | null;
  className?: string;
}

const IncomingTextMessage: FC<IIncomingTextMessage> = ({
  title,
  text,
  link,
  className
}) => (
  <SWrapper className={cn(className)}>
    {title ? <SMessageTitle>{title}</SMessageTitle> : null}

    {text ? <SMessageText dangerouslySetInnerHTML={{ __html: text || '' }} /> : null}

    {link && link.url ? <SMessageLink to={link.url}>{link.title}</SMessageLink> : null}
  </SWrapper>
);

export default IncomingTextMessage;

const SWrapper = styled.div`
  width: 100%;
  word-break: break-word;
`;

const SMessageTitle = styled.h4`
  margin: 0 0 4px;
  color: var(--heading-text-color);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.75rem;
`;

const SMessageText = styled.p`
  margin: 0;
  color: var(--heading-text-color);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;

  & img {
    max-width: 100%;
  }
`;

const SMessageLink = styled(LinkElem)`
  text-align: right;
  width: 100%;
  display: inline-block;
  font-size: 0.875rem;
  color: var(--color-blue);
  text-decoration: none;
  margin-block-end: -18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.3s;

  &:hover {
    text-decoration: none;
    color: var(--color-blue);
    opacity: 0.75;
  }
`;
