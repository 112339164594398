import { tClientLicences } from '@adeptlms/lingu-students-react-shared';
import { Popover } from '@headlessui/react';
import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { customMediaQuery, linkStyles } from '../../styled';
import LicenceLabel from './LicenceActionCard/Cources/LicenceLabel';
import StackedLicenses from './StackedLicenses';

type tProps = {
  licenses: tClientLicences;
  className?: string;
};
const StackedLicensesDropdown: FC<PropsWithChildren<tProps>> = ({
  children,
  licenses,
  className
}) => {
  const activeLicenses = licenses.filter(
    (license) => license.computed.status !== 'inactive'
  );

  if (activeLicenses.length === 0) return null;

  const isStacked = activeLicenses.length > 1;

  const [firstLicense] = activeLicenses;
  return (
    <SWrapper className={cn(className)}>
      <Popover>
        <SButton>
          {isStacked ? (
            <SStackedWrapper>
              <StackedLicenses licenses={activeLicenses} />
              <SStackedText>
                <Translate
                  str="frontend.licences.n_licenses_are_available"
                  params={{
                    defaultValue: '%{number} licenses are available',
                    number: activeLicenses.length
                  }}
                />
              </SStackedText>
            </SStackedWrapper>
          ) : (
            <SLicenceLabel license={firstLicense} withTeamName />
          )}
        </SButton>
        <SPopoverPanel>
          {activeLicenses.map((licenseListItem, index) => (
            <SPanelListItem key={index}>
              <SLicenceLabel license={licenseListItem} withTeamName />
            </SPanelListItem>
          ))}
          <SPanelListItem>
            <SLink to="/settings/licenses">
              <Translate
                str="frontend.licences.show_licenses_details"
                params={{ defaultValue: 'Show licences details' }}
              />{' '}
              {'->'}
            </SLink>
          </SPanelListItem>
        </SPopoverPanel>
      </Popover>
      {children}
    </SWrapper>
  );
};

export default StackedLicensesDropdown;

const SWrapper = styled.div`
  position: relative;

  & .action-menu_menu-wrapper {
    position: relative;
  }

  & .action-menu_item {
    margin: 0 0 1rem;
  }
  & .action-menu_item:last-child {
    margin: 0;
  }
`;

const SPanelListItem = styled.div`
  padding: 8px;
  width: 100%;
`;

const SLicenceLabel = styled(LicenceLabel)`
  width: 100%;
`;
const SStackedWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SStackedText = styled.div`
  margin-inline-start: 1rem;
  font-size: 1rem;
  text-align: start;
  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
    padding-inline-start: 1.5rem;
  }
`;

const SPopoverPanel = styled(Popover.Panel)`
  background: var(--modal-background);
  box-shadow: var(--card-shadow);
  margin-block-start: 6px;
  position: absolute;
  overflow: auto;
  max-height: 400px;
  border-radius: 16px;
  padding: 12px;
  width: 100%;
  z-index: 3;
`;

const SLink = styled(NavLink)`
  ${linkStyles}
  margin-inline-start:  16px;
`;
const SButton = styled(Popover.Button)`
  width: 100%;
  padding: 1rem;
  color: var(--heading-text-color);
  background: var(--common-licence-card-background);
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;

  ${customMediaQuery('tablet')} {
    border-radius: 20px;
  }
`;
