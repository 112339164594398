import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import bookIcon from '../assets/book_icon.svg';
import followersIcon from '../assets/followers_icon.svg';

interface IAssignmentMetrics {
  lessonsCount: number | undefined;
  followersCount: number | undefined;
  rating: number | undefined;
  className?: string;
}

const AssignmentMetrics: FC<IAssignmentMetrics> = ({
  lessonsCount,
  followersCount,
  rating: _rating,
  className
}) => (
  <SWrapper className={cn(className)}>
    {lessonsCount ? (
      <SMetric>
        <UrlIcon url={bookIcon} height="18px" width="18px" color="#5E5D71" />

        <SText>
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.pluralized_lessons_count"
            params={{ count: lessonsCount }}
          />
        </SText>
      </SMetric>
    ) : null}

    {followersCount ? (
      <SMetric>
        <UrlIcon url={followersIcon} height="18px" width="18px" color="#5E5D71" />

        <SText>
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.pluralized_followers_count"
            params={{ count: followersCount }}
          />
        </SText>
      </SMetric>
    ) : null}

    {/* <SMetric> */}
    {/*  <UrlIcon url={starIcon} height="18px" width="18px" color="#5E5D71" /> */}

    {/*  <SText>{rating}</SText> */}
    {/* </SMetric> */}
  </SWrapper>
);

export default AssignmentMetrics;

const SWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SMetric = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 12px;

  & > div {
    margin-inline-end: 2px;
  }

  &:last-child {
    margin-inline-end: 0;
  }
`;

const SText = styled.h3`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--sub-text-color);
  margin: 0;
  padding: 0;
  text-transform: lowercase;

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
  }
`;
