import cn from 'classnames';
import WaveAudioPlayer from 'common/modules/WaveAudioPlayer';
import { FC } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { WaveSurferParams } from 'wavesurfer.js';

interface IAudioMessage {
  id: string;
  audioURL: string;
  audioSize?: number;
  className?: string;
}

const AudioMessage: FC<IAudioMessage> = ({ id, audioURL, audioSize, className }) => (
  <SWrapper className={cn(className)}>
    <SWaveAudioPlayer
      id={`waveAudioPlayer_${id}`}
      url={audioURL}
      size={audioSize}
      isDownloadable={false}
      customOptions={{ waveColor: 'rgb(172, 172, 183)' } as WaveSurferParams}
    />
  </SWrapper>
);

export default AudioMessage;

const SWrapper = styled.div`
  width: 100%;
`;

const SWaveAudioPlayer = styled(WaveAudioPlayer)`
  background: var(--assignments-message-out-background);
  border-radius: 10px;
  padding: 12px 12px 12px 4px;
  height: 72px;

  & > svg {
    margin-inline-start: 0;
  }

  ${customMediaQuery('tablet')} {
    padding: 16px 16px 16px 4px;
    width: 100%;
  }
`;
