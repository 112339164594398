import { IChatMessage } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { FC } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import aiBadge from '../assets/ai_badge.png';
import IncomingMessageFooter from './IncomingMessageFooter';
import IncomingTextMessage from './IncomingTextMessage';

interface IIncomingMessage {
  message: IChatMessage;
  messageHeader?: string | null;
  collapseWithNext: boolean;
  className?: string;
}

const IncomingAIMessage: FC<IIncomingMessage> = ({
  message,
  messageHeader,
  collapseWithNext,
  className
}) => {
  if (!message.title && !message.text) {
    return null;
  }

  return (
    <SWrapper className={cn({ collapseWithNext })}>
      {messageHeader ? <SMessageHeader>{messageHeader}</SMessageHeader> : null}

      <SMessage className={cn(className, { collapseWithNext })}>
        <IncomingTextMessage
          title={message.title}
          text={message.text}
          link={message.link}
        />
        <SBadge src={aiBadge} alt={message?.author?.name ?? 'AI'} />
      </SMessage>

      {message.author?.name && !collapseWithNext ? (
        <IncomingMessageFooter
          authorName={message.author.name}
          authorInitials={message.author.initials}
          authorAvatar={message.author.avatar}
        />
      ) : null}
    </SWrapper>
  );
};

export default IncomingAIMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-inline-start: 44px;
  margin-block-end: 20px;
  position: relative;

  &.collapseWithNext {
    border-bottom-left-radius: 10px;
    margin-block-end: 0;
  }

  ${customMediaQuery('tablet')} {
    margin-inline-start: 62px;
  }
`;

const SMessageHeader = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1rem;
  color: var(--heading-text-color);
  opacity: 0.5;
  margin: 0 0 8px;

  ${customMediaQuery('tablet')} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const SMessage = styled.div`
  background: #4e35e814;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  padding: 16px;
  width: 100%;
  max-width: 90%;
  margin-block-end: 4px;

  &.collapseWithNext {
    border-bottom-left-radius: 10px;
  }

  ${customMediaQuery('tablet')} {
    padding: 20px;
    max-width: 85%;
    margin-block-end: 8px;
  }
`;

const SBadge = styled.img`
  --badge-size: 36px;
  position: absolute;
  top: calc(0px - var(--badge-size) / 4);
  right: calc(0px - var(--badge-size) / 4);
  width: var(--badge-size);
  height: var(--badge-size);
`;
