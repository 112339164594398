/* eslint-disable jsx-a11y/no-autofocus */
import cn from 'classnames';
import { motion } from 'framer-motion';
import { Translate, t } from 'i18n';
import { ChangeEvent, FC, ReactNode, useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import crossIcon from 'students/views/shared/assets/icons/close_icon.svg';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { useBreakPoint } from 'students/views/shared/hooks';
import { getPreference } from 'students/views/shared/providers/Preferences/helpers';
import { Preferences } from 'students/views/shared/providers/Preferences/models';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import DoneIcon from '../../../../assets/done_icon.svg';
import SendIcon from '../../../../assets/send_icon.svg';
import ShortEssayConfirmationModal from '../../../ShortEssayConfirmationModal';

interface IReplyTextarea {
  onSend: (answer: string) => void;
  minimumWordsCount: number;
  defaultValue?: string;
  isLoading: boolean;
  onClose?: () => void;
  className?: string;
  disabledSubmit?: boolean;
  forbidSameAnswer?: boolean;
  replyError?: ReactNode;
}

const ReplyTextarea: FC<IReplyTextarea> = ({
  onSend,
  minimumWordsCount,
  defaultValue,
  isLoading,
  disabledSubmit,
  forbidSameAnswer,
  onClose,
  replyError,
  className
}) => {
  const isMobile = useBreakPoint('md', true);
  const [inputValue, setInputValue] = useState<string>(defaultValue || '');
  const [showModal, setShowModal] = useState(false);
  const isPasteAllowed = JSON.parse(getPreference(Preferences.EssayAIPaste, 'false'));

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  const handleSend = () => {
    if (currentWordsCount < minimumWordsCount) {
      setShowModal(true);
    } else {
      onSend(inputValue);
    }
  };

  const currentWordsCount = inputValue.match(/[^\s]+/g)?.length || 0;
  const isSameAnswer = inputValue.trim() === defaultValue?.trim();
  return (
    <>
      <SWrapper
        layout
        className={cn(className)}
        initial={{ opacity: 0, scaleY: 0 }}
        animate={{ opacity: 1, scaleY: 1 }}
      >
        {replyError}

        <STextareaWrapper>
          <TextareaAutosize
            autoFocus
            rows={isMobile ? 1 : 5}
            value={inputValue}
            onChange={handleChange}
            onPaste={(e) => (isPasteAllowed ? null : e.preventDefault())}
            disabled={isLoading}
            placeholder={`${t(
              'frontend.lesson_task.tasks.check.assignment.type_your_answer'
            )}...`}
          />
        </STextareaWrapper>

        <SFooter>
          <SRequirementText>
            <Translate
              str="frontend.lesson_task.tasks.check.assignment.minimum_words_amount"
              params={{
                minimumAmount: minimumWordsCount
              }}
            />
          </SRequirementText>

          <SRequirementBlock>
            <SSendButton
              disabled={
                disabledSubmit ||
                !currentWordsCount ||
                isLoading ||
                (!!forbidSameAnswer && isSameAnswer)
              }
              onClick={handleSend}
            >
              <UrlIcon url={SendIcon} height="28px" width="28px" color="#00A5D7" />
            </SSendButton>

            <SRequiredSymbols>
              {currentWordsCount >= minimumWordsCount && (
                <SCheckIcon>
                  <UrlIcon url={DoneIcon} color="#ffffff" height="9px" width="9px" />
                </SCheckIcon>
              )}

              <SRequiredSymbolsText
                className={cn({
                  notEmpty: currentWordsCount > 0,
                  correct: currentWordsCount >= minimumWordsCount
                })}
              >
                {currentWordsCount}/{minimumWordsCount}
              </SRequiredSymbolsText>
            </SRequiredSymbols>
          </SRequirementBlock>
        </SFooter>

        {typeof onClose === 'function' ? (
          <SCloseBtn onClick={onClose}>
            <UrlIcon
              url={crossIcon}
              height="16px"
              width="16px"
              color="var(--heading-text-color)"
            />
          </SCloseBtn>
        ) : null}
      </SWrapper>

      <ShortEssayConfirmationModal
        onSend={() => onSend(inputValue)}
        show={showModal}
        close={() => setShowModal(false)}
        message={
          <Translate
            str="frontend.lesson_task.tasks.check.assignment.essay_does_not_have_enough_words"
            params={{
              minWordsNumber: minimumWordsCount,
              currentWordsNumber: currentWordsCount
            }}
          />
        }
        continueBtnText={
          <Translate str="frontend.lesson_task.tasks.check.assignment.continue_writing" />
        }
      />
    </>
  );
};

export default ReplyTextarea;

const SWrapper = styled(motion.div)`
  transform-origin: bottom;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  background-color: var(--assignments-feedback-message-background);
  padding: 16px 52px 16px 16px;

  ${customMediaQuery('tablet')} {
    background-color: var(--assignments-reply-input-background);
    border-radius: 14px;
    padding: 24px 32px 24px 24px;
  }
`;

const STextareaWrapper = styled.div`
  width: 100%;
  margin-block-end: 32px;
  z-index: 1;

  & > textarea {
    width: 100%;
    background: transparent;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--heading-text-color);
    resize: none;

    &:focus,
    &:active {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: var(--sub-text-color);
    }

    ${customMediaQuery('tablet')} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const SFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
`;

const SRequirementText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  color: var(--sub-text-color);
  opacity: 0.8;

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const SRequirementBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${customMediaQuery('tablet')} {
    flex-direction: row-reverse;

    & > div {
      margin-inline-end: 20px;
    }
  }
`;

const SRequiredSymbols = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div:first-child {
    margin-inline-end: 4px;
  }
`;

const SCheckIcon = styled.div`
  background: var(--color-green);
  border-radius: 50%;
  padding: 3px;

  ${customMediaQuery('tablet')} {
    height: 15px;
    width: 15px;
  }
`;

const SRequiredSymbolsText = styled.p`
  margin: 0;
  padding: 0;
  color: var(--sub-text-color);
  opacity: 0.8;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;

  &.notEmpty {
    opacity: 1;
  }

  &.correct {
    color: var(--color-green);
  }

  ${customMediaQuery('tablet')} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;

    &.notEmpty {
      opacity: 1;
      font-weight: 600;
    }
  }
`;

const SSendButton = styled(ButtonGeneral)<{ disabled: boolean }>`
  background: transparent;
  border: none;
  margin: 0 0 14px;
  padding: 0;
  border-radius: 4px;

  &:disabled {
    filter: grayscale(1);
  }

  ${customMediaQuery('tablet')} {
    margin: 0;
  }
`;

const SCloseBtn = styled(ButtonGeneral)`
  border: none;
  background: var(--card-background-color);
  border-radius: 50%;
  position: absolute;
  top: -36px;
  padding: 8px;
  right: 8px;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  ${customMediaQuery('tablet')} {
    background: transparent;
    top: 10px;
    right: 6px;
  }
`;
