import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import LinkElem from 'students/views/shared/components/LinkElem';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import styled from 'styled-components';
import WaveSurfer, { WaveSurferParams } from 'wavesurfer.js';
import DownloadIcon from '../assets/download.svg';
import { seconds2timestring } from '../utils/seconds2timestring';
import pauseIcon from './assets/pause_icon.svg';
import playIcon from './assets/play_icon.svg';

interface IProps {
  url: string;
  size?: number;
  customOptions?: WaveSurferParams;
  isDownloadable: boolean;
  id: string;
  className?: string;
}

enum AudioState {
  Initial,
  Playing,
  Pausing
}

const WaveAudioPlayer: React.FC<IProps> = ({
  url,
  size,
  isDownloadable = true,
  customOptions,
  id,
  className
}) => {
  const [state, setState] = useState<AudioState>(AudioState.Initial);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const waveform = useRef<WaveSurfer | null>(null);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    waveform.current = WaveSurfer.create({
      barWidth: 1,
      barGap: 2,
      minPxPerSec: 100,
      cursorWidth: 1,
      container: `#waveform_${id}`,
      height: 60,
      partialRender: true,
      progressColor: '#00A5D7',
      waveColor: 'var(--sub-text-color)',
      cursorColor: 'transparent',
      ...customOptions
    });
    waveform.current.load(url);
    waveform.current.on('ready', function () {
      waveform.current && setDuration(waveform.current.getDuration());
    });
    waveform.current.on('seek', function () {
      waveform.current && setCurrentTime(waveform.current.getCurrentTime());
    });
    waveform.current.on('finish', function () {
      setState(AudioState.Pausing);
    });

    return () => {
      waveform.current && waveform.current.destroy();
    };
  }, [url, id, customOptions]);

  useEffect(() => {
    if (state === AudioState.Playing) {
      intervalRef.current = window.setInterval(() => {
        if (waveform.current) setCurrentTime(waveform.current.getCurrentTime());
      }, 1000);
    } else {
      intervalRef?.current && clearInterval(intervalRef.current);
    }

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [state]);

  const handlePlay = () => {
    if (waveform.current) {
      waveform.current.playPause();
      switch (state) {
        case AudioState.Initial: {
          setState(AudioState.Playing);
          break;
        }
        case AudioState.Playing: {
          setState(AudioState.Pausing);
          break;
        }
        case AudioState.Pausing: {
          setState(AudioState.Playing);
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <SWaveformContainer className={cn(className)}>
      <SIconButton onClick={handlePlay}>
        <UrlIcon
          url={
            state === AudioState.Initial || state === AudioState.Pausing
              ? playIcon
              : pauseIcon
          }
          height="38px"
          width="38px"
        />
      </SIconButton>

      <SWave id={`waveform_${id}`} />

      {state === AudioState.Initial ? (
        <STimeContainer>
          {seconds2timestring(duration)}
          {size ? <SAudioSize>{`${size} MB`}</SAudioSize> : null}
        </STimeContainer>
      ) : (
        <STimeContainer>
          {`${seconds2timestring(currentTime)} / ${seconds2timestring(duration)}`}
        </STimeContainer>
      )}

      {isDownloadable && (
        <SAudioLinkContainer>
          <LinkElem aria-label="Download" to={url}>
            <img src={DownloadIcon} alt="download" />
          </LinkElem>
        </SAudioLinkContainer>
      )}
    </SWaveformContainer>
  );
};

export default WaveAudioPlayer;

const SWave = styled.div`
  width: 100%;
  height: 30px;
  margin-block-start: k-start: -30px;
  overflow: hidden;
`;

const SWaveformContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px;
  width: 372px;
  background: rgba(240, 240, 243, 0.5);
  border-radius: 6px;
`;

const SIconButton = styled(ButtonGeneral)`
  background: var(--wave-audio-player-icon-background);
  border-radius: 50%;
  margin-inline-start: 14px;
  margin-inline-end: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const STimeContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 65px;
  font-size: 12px;
  line-height: 14px;
  color: var(--sub-text-color);
`;

const SAudioSize = styled.span`
  margin-inline-start: 8px;
`;

const SAudioLinkContainer = styled.div`
  width: 44px;
  height: 32px;
  background: #00a5d7;
  background: rgba(0, 165, 215, 0.1);
  border-radius: 4px;
  margin-inline-end: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
