import cn from 'classnames';
import React from 'react';
import avatarImg from 'students/views/shared/assets/avatar.svg';
import styled, { css } from 'styled-components';
import Picture from '../Picture';

type tItem = { url: string | null; id: string; alt: string };
type tProps = {
  items: tItem[];
  maxCount?: number;
  className?: string;
  withNumber?: boolean;
  avatarWrapperClassName?: string;
  counterClassName?: string;
  render?: (item: tItem) => React.ReactElement;
};
const StackedAvatars: React.FC<tProps> = ({
  maxCount = 3,
  className,
  items,
  render,
  avatarWrapperClassName,
  counterClassName,
  withNumber
}) => {
  const hasCustomRender = typeof render === 'function';
  const hiddenItemsNumber = items.length - maxCount;
  const avatars = items
    .map((item, index) => {
      if (index < maxCount) {
        const avatar = hasCustomRender ? (
          render(item)
        ) : (
          <SAvatar src={item.url || avatarImg} alt={item.alt} title={item.alt} lazy />
        );

        return (
          <SWrapper
            key={item.id}
            className={cn(avatarWrapperClassName, { hasCustomRender })}
          >
            {avatar}
          </SWrapper>
        );
      }
      return null;
    })
    .filter(Boolean);

  const numberBlock =
    withNumber && hiddenItemsNumber > 0 ? (
      <SNumberBlock className={cn(counterClassName, { hasCustomRender })}>
        +{hiddenItemsNumber}
      </SNumberBlock>
    ) : null;

  return (
    <SContainer className={cn(className)}>
      {avatars}
      {numberBlock}
    </SContainer>
  );
};

export default StackedAvatars;

const SContainer = styled.div`
  display: inline-flex;
  padding-inline-start: 25px;
`;
const itemStyles = css`
  border-radius: 50%;
  box-shadow: var(--card-shadow);
  margin-inline-start: -25px;
  aspect-ratio: 1;
  overflow: hidden;

  :not(&.hasCustomRender) {
    width: 32px;
    height: 32px;
  }
`;
const SWrapper = styled.div`
  border: 2px solid #ffffff;

  ${itemStyles}
`;
const SAvatar = styled(Picture)`
  width: 100%;
  margin-block-start: 0;
`;

const SNumberBlock = styled.div`
  ${itemStyles}
  height: auto;
  color: #ffffffaa;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #353c55;
`;
