import React, { FC } from 'react';
import { tGiphyImage } from '@adeptlms/lingu-students-react-shared';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';
import cn from 'classnames';
import ImageContent from 'students/views/shared/components/ImageContent/ImageContent';

interface IIncomingImageMessage {
  image?: string | null;
  alt: string;
  smallImage?: string | null;
  cover?: boolean;
  giphyImage?: tGiphyImage | null;
  responsiveGiphy?: boolean;
  className?: string;
}

const IncomingImageMessage: FC<IIncomingImageMessage> = ({
  image,
  alt,
  smallImage,
  cover,
  giphyImage,
  responsiveGiphy,
  className
}) => (
  <SWrapper className={cn(className)}>
    <Zoom wrapStyle={{ width: '100%' }} overlayBgColorEnd="rgba(45, 45, 58, 0.9)">
      <SImageContent
        image={image}
        smallImage={smallImage}
        cover={cover}
        giphyImage={giphyImage}
        responsiveGiphy={responsiveGiphy}
        alt={alt}
      />
    </Zoom>
  </SWrapper>
);

export default IncomingImageMessage;

const SWrapper = styled.div`
  width: 100%;
`;

const SImageContent = styled(ImageContent)`
  border-radius: 10px;
  padding: 0;
`;
